<template>
  <v-container fill-height fluid class="pagenotfound--container">
    <v-container>
      <div class="forgotpassword--dialog">
        <div class="forgotpassword--img">
          <img
            src="../assets/information-icon.png"
            width="23"
            height="23"
            class="loginform-btn-icon-padding forgotpassword--image"
          />
        </div>
        <span class="text--medium--14 forgotpassword--dialog--text">
          รหัสผ่านที่ได้ทำการเปลี่ยนแปลงนั้น
          จะใช้สำหรับการเข้าสู่ระบบโดยการกรอกอีเมลและรหัสผ่านที่ถูกเปลี่ยนแปลง
          เพื่อเข้าในระบบการขอใช้พื้นที่ฯ</span
        >
      </div>
    </v-container>

    <!-- <v-container v-if="loginType === 'google' || loginType == 'facebook'">
      <div class="forgotpassword--dialog">
        <div class="forgotpassword--img">
          <img
            src="../assets/information-icon.png"
            width="23"
            height="23"
            class="loginform-btn-icon-padding forgotpassword--image"
          />
        </div>
        <span class="text--medium--14 forgotpassword--dialog--text">
          หากท่านได้ทำการสมัครด้วย Facebook หรือ Google
          โปรดดำเนินการเปลี่ยนรหัสผ่าน ในช่องทาง Facebook หรือ Google
          ที่ท่านได้สมัครไว้</span
        >
      </div>
    </v-container> -->

    <v-row align="center" justify="center">
      <v-col align="center">
        <p
          class="ma-0 text--bold--20 pagenotfound--title--text"
          v-if="!titleType"
        >
          ลืมรหัสผ่าน
        </p>
        <p
          class="ma-0 text--bold--20 pagenotfound--title--text"
          v-if="titleType === 'resetpassword'"
        >
          เปลี่ยนรหัสผ่าน
        </p>
        <p class="ma-0 text--regular--14 forgot-password-title hidden-xs-only">
          ใส่อีเมลที่ท่านมีบัญชีอยู่ในระบบอยู่แล้ว
          เพื่อทำการส่งไปยังอีเมลที่ท่านระบุไว้ <br />
          สำหรับการตั้งค่ารหัสผ่านใหม่
        </p>
        <p
          class="ma-0 text--regular--14 forgot-password-title  hidden-sm-and-up hidden-md-and-up hidden-sm-and-up hidden-xl-and-up"
        >
          ใส่อีเมลที่ท่านมีบัญชีอยู่ในระบบอยู่แล้ว เพื่อทำการส่งไปยังอีเมล
          <br />
          ที่ท่านระบุไว้สำหรับการตั้งค่ารหัสผ่านใหม่
        </p>
        <v-container
          class="text-center col-md-12 col-12 col-sm-12 col-12 pagenotfound--btn--container pt-0 pb-0"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="loginform-gmail-padding"
          >
            <v-row align="center" justify="center">
              <v-col
                cols="12"
                sm="12"
                md="3"
                align="center"
                class="pt-0 pt-0 pb-0"
              >
                <p
                  class="mb-0 text--bold--14"
                  style="text-align: start; margin-bottom: 6px !important;"
                >
                  อีเมล
                </p>
                <v-text-field
                  v-model="email"
                  single-line
                  label="อีเมลของท่าน"
                  outlined
                  required
                  dense
                  type="test"
                  :rules="emailRules"
                  class="loginform-gmail-text-field"
                ></v-text-field>
                <div v-if="resetPasswordResponse">
                  <span
                    class="mb-0 red--text"
                    v-if="resetPasswordResponse.error"
                    >{{ resetPasswordResponse.error.message }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-form>
          <v-btn
            elevation="0"
            medium
            rounded
            color="primary"
            @click="onResetPassword"
            class="pagenotfound--btn"
          >
            <span class="black--text text--regular--14"
              >ส่งคำขอการเปลี่ยนรหัสผ่าน</span
            ></v-btn
          >
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  created() {
    if (this.$route.params.param) {
      this.titleType = this.$route.params.param;
    }
    //  else {
    //   this.$router.push("/");
    // }
  },
  data: () => ({
    loginType: localStorage.getItem("logintype"),
    titleType: "",
    email: "",
    valid: true,
    emailRules: [
      v => !!v || "กรุณาระบุ อีเมล",
      v => /.+@.+\..+/.test(v) || "กรุณาระบุ อีเมลให้ถูกต้อง"
    ],
    resetPasswordResponse: null,
    isShowMessage: false
  }),
  methods: {
    ...mapActions("user", ["forgotPassword"]),
    async onResetPassword() {
      if (this.$refs.form.validate()) {
        this.resetPasswordResponse = null;
        this.resetPasswordResponse = await this.forgotPassword({
          email: this.email
        });
        if (this.resetPasswordResponse) {
          if (this.resetPasswordResponse.status === 200) {
            this.isShowMessage = true;
            // let penddingParam = {
            //   email: this.email,
            //   status: "resetpassword",
            // };
            // let jsonStringPendding = JSON.stringify(penddingParam);
            // let jsonBase64Peddding = btoa(jsonStringPendding);
            // this.$router.push(`/resetpassword/${jsonBase64Peddding}`);
          }
        }
      }
    }
  }
};
</script>

<style scoped>
@media (max-width: 480px) {
  .forgotpassword--dialog {
    margin-top: 11px;
    background-color: #f5d157;
    height: 115px;
    text-align: center;
    padding: 10px;
  }

  .forgotpassword--dialog--text {
    /* padding-top: 25px;
    padding-bottom: 25px; */
  }

  .forgotpassword--img {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .pagenotfound--img {
    width: 284px;
    height: 214px;
  }

  .pagenotfound--btn {
    /* padding: 12px 78px 12px 78px !important; */
    margin-top: 15px;
    height: 45px !important;
  }

  .pagenotfound--btn--container {
    margin-top: 56px;
    /* height: 54px; */
  }

  .pagenotfound--title--text {
    margin-top: 37px !important;
  }

  .pagenotfound--container {
    padding-top: 0px;
    padding-bottom: 337.5px;
  }
  .forgot-password-title {
    margin-top: 11px !important;
  }
}

@media (min-width: 481px) {
  .forgotpassword--img {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .pagenotfound--img {
    width: 376px;
    height: 284px;
  }
  .pagenotfound--btn {
    margin-top: 15px;
    /* padding: 12px 78px 12px 78px !important; */
    height: 45px !important;
  }

  .pagenotfound--btn--container {
    margin-top: 47px;
  }

  .pagenotfound--title--text {
    margin-top: 46px !important;
  }

  .pagenotfound--container {
    padding-top: 84px;
    padding-bottom: 116px;
  }
  .forgot-password-title {
    margin-top: 26px !important;
  }

  .forgotpassword--dialog {
    margin-top: 11px;
    background-color: #f5d157;
    height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .forgotpassword--dialog--text {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 18px;
  }
}
</style>
